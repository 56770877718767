
.App {
  width: 100%;
  height: 100%;
}

#picked {
  font-size: 500px;
  text-align: center;
}

.io {
  text-align: center;
}

#picked {
  height: 950px;
}